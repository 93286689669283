import { Card } from 'components/card';
import { FileField } from 'components/fields';
import { Form } from 'components/form';
import { ManualMetadataStep } from 'components/sso/steps/saml/manual-metadata-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { ChangeEvent, useState } from 'react';

type SelectedConfiguration = 'dynamic' | 'manual';

export interface MetadataXmlStepProps extends ConnectionStepProps {
  stepTitle: string;
  idpEntityIdPlaceholder: string;
  idpSsoUrlPlaceholder: string;
}

export const MetadataXmlStep: React.FC<MetadataXmlStepProps> = ({
  children,
  idpEntityIdPlaceholder,
  idpSsoUrlPlaceholder,
  stepTitle,
  ...connectionStepProps
}) => {
  const {
    connectionUpdatedFields,
    errors,
    isLoading,
    onNextStep,
    setConnectionUpdatedFields,
  } = connectionStepProps;

  const [selectedConfiguration, setSelectedConfiguration] =
    useState<SelectedConfiguration>('dynamic');

  const onXmlInput = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (event?.target?.files) {
      const file = event?.target?.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setConnectionUpdatedFields({
            saml_idp_metadata_xml: reader.result?.toString(),
          });
        };

        reader.readAsText(file);
      }
    }
  };

  return (
    <Article>
      <Title className="mb-4">{stepTitle}</Title>

      {selectedConfiguration === 'dynamic' && (
        <>
          {children}

          <Card>
            <Form
              disabled={!connectionUpdatedFields?.saml_idp_metadata_xml}
              isLoading={isLoading}
              isUpdate={!!errors?.saml_idp_metadata_xml}
              onSubmit={onNextStep}
              secondaryAction={() => setSelectedConfiguration('manual')}
              secondaryText="Configure Manually"
            >
              <FileField
                accept=".xml"
                error={errors?.saml_idp_metadata_xml}
                filename="XML Metadata File"
                label="XML Metadata File"
                name="saml_idp_metadata_xml"
                onUpload={onXmlInput}
                value={connectionUpdatedFields?.saml_idp_metadata_xml}
              />
            </Form>
          </Card>
        </>
      )}

      {selectedConfiguration === 'manual' && (
        <ManualMetadataStep
          {...connectionStepProps}
          idpEntityIdPlaceholder={idpEntityIdPlaceholder}
          idpSsoUrlPlaceholder={idpSsoUrlPlaceholder}
          onConfigurationTypeChange={setSelectedConfiguration}
        />
      )}
    </Article>
  );
};
